define("happyfox-bi/reports/details/pages/details/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    dataService: Ember.inject.service('data'),
    toastMessageService: Ember.inject.service('toast-message'),
    featuresService: Ember.inject.service('features'),
    profileService: Ember.inject.service('profile'),
    reportId: null,
    model: function model(params) {
      var _this = this;

      var page_id = params.page_id;
      var reportParams = this.paramsFor('reports.details');
      this.set('reportId', reportParams.id);
      return this.get('dataService').getPage(this.reportId, page_id).catch(function (_ref) {
        var status = _ref.status;

        if (status === 404) {
          _this.transitionTo('reports');
        } else {
          _this.transitionTo('error');
        }
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      var reportId = this.get('reportId');
      var reportsList = this.dataService.peekAllReports();
      controller.setProperties({
        'isStaticGrid': !this.get('featuresService.createPages'),
        'reportId': reportId,
        'canShowEditPageModal': false,
        'canShowSampleVisualizations': false,
        'temporaryVisualizations': Ember.A(),
        'seekedDateRange': Ember.Object.create({
          'dateRange': 'custom',
          'fromDate': '',
          'toDate': ''
        }),
        reportsList: reportsList
      });
    },
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        controller.set('canShowSubscriptionSidePane', false);
      }
    },
    actions: {
      didTransition: function didTransition() {
        var controller = this.controller;
        controller.initializeAutoRefresh();
        controller.set('pageLastLoadedAt', moment());
      },
      willTransition: function willTransition() {
        var controller = this.controller;
        controller.removeAutoRefresh();
      },
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});