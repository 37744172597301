define("happyfox-bi/manage/calculated-fields/add/calculation-step/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Rxo9f+y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,0,\"hf-wizard_content_step-header\"],[12],[2,\"\\n  \"],[1,[30,[36,7],[\"calculation-step-header\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"header\"],[14,0,\"hf-wizard_content_step-description\"],[12],[2,\"\\n  \"],[1,[30,[36,7],[\"calculation-step-description\"],null]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"hf-u-center-content\"],[12],[2,\"\\n    \"],[19,\"templates/partials/loading\",[]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"calculation-step-fields-container\"],[12],[2,\"\\n\"],[2,\"\\n    \"],[1,[30,[36,6],null,[[\"datasetRelatedData\",\"calculationType\",\"configuration\",\"errors\",\"additionalData\",\"onConfigurationChange\"],[[35,5],[35,4,[\"value\"]],[35,3],[35,2,[\"calculatedFieldConfig\"]],[35,1],[30,[36,0],[[32,0],\"onCalculatedConfigChange\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":true,\"upvars\":[\"action\",\"additionalData\",\"errors\",\"calculatedFieldConfig\",\"selectedCalculationTypeOption\",\"datasetRelatedData\",\"manage/calculated-fields/components/calculation-field\",\"t\",\"isLoadingDependencies\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/add/calculation-step/template.hbs"
    }
  });

  _exports.default = _default;
});