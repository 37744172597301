define("happyfox-bi/manage/calculated-fields/edit/controller", ["exports", "happyfox-bi/manage/calculated-fields/create-edit-controller"], function (_exports, _createEditController) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _createEditController.default.extend({
    isEditRoute: true
  });

  _exports.default = _default;
});