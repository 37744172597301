define("happyfox-bi/manage/calculated-fields/components/actions-cell/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    routerService: Ember.inject.service('router'),
    actions: {
      editCalculatedField: function editCalculatedField() {
        this.routerService.transitionTo("manage.calculated-fields.edit", this.row.content.id);
      }
    }
  });

  _exports.default = _default;
});