define("happyfox-bi/manage/calculated-fields/partials/create-edit-calculated-field/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "roSbW59Y",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content_outlet_main add_calculated_fields_main\"],[12],[2,\"\\n\"],[6,[37,3],[[32,0,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[19,\"templates/partials/loading\",[]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"steps\",\"data\",\"errors\",\"additionalData\",\"updateData\",\"updateErrors\",\"formSubmitAction\",\"submitTextKey\",\"submitProgressTextKey\",\"submitInProgress\",\"supportsConfirmationDialog\",\"confirmationDialogTitle\",\"confirmationDialogDescription\",\"confirmationDialogPrimaryActionText\",\"confirmationDialogSecondaryActionText\"],[[32,0,[\"steps\"]],[32,0,[\"datasetFieldData\"]],[32,0,[\"errors\"]],[32,0,[\"additionalData\"]],[30,[36,1],[[32,0],\"updateDatasetFieldData\"],null],[30,[36,1],[[32,0],\"updateErrors\"],null],[30,[36,1],[[32,0],\"createEditCalculatedField\"],null],\"submit\",\"submitting\",[32,0,[\"isCreateEditInProgress\"]],[32,0,[\"supportsConfirmationDialog\"]],[30,[36,0],[[32,0,[\"confirmationDialogTitle\"]]],null],[30,[36,0],[[32,0,[\"confirmationDialogDescription\"]]],null],[30,[36,0],[[32,0,[\"confirmationDialogPrimaryActionText\"]]],null],[30,[36,0],[[32,0,[\"confirmationDialogSecondaryActionText\"]]],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":true,\"upvars\":[\"t\",\"action\",\"wizard-basic\",\"if\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/partials/create-edit-calculated-field/template.hbs"
    }
  });

  _exports.default = _default;
});