define("happyfox-bi/components/wizard-flow/step-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    labelKey: '',
    form: null,
    fields: null,
    stepComponent: null,
    errors: null,
    validate: function validate() {
      var validationPromises = Ember.A();
      this.get('fields').forEach(function (field) {
        validationPromises.pushObject(field.validate());
      });
      return Ember.RSVP.all(validationPromises);
    },
    reset: function reset() {
      this.get('fields').forEach(function (field) {
        return field.reset();
      });
      this.set('isVisited', false);
    }
  });

  _exports.default = _default;
});