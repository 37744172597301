define("happyfox-bi/components/wizard-flow/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['hf-wizard'],
    classNameBindings: ['isDarkMode:hf-mod-dark-mode'],
    steps: null,
    firstStep: null,
    lastStep: null,
    form: null,
    submitTextKey: null,
    submitProgressTextKey: null,
    submitInProgress: false,
    resetBoolean: false,
    init: function init() {
      this._super.apply(this, arguments);

      var steps = this.get('steps');
      steps.forEach(function (stepInfo) {
        stepInfo.setProperties({
          hasNext: true,
          hasPrevious: true,
          isVisited: false,
          isCurrentStep: false,
          isVisitedOnce: false
        });
      });
      var firstStep = steps.get('firstObject');
      firstStep.setProperties({
        hasPrevious: false,
        isCurrentStep: true,
        isVisited: true
      });
      steps.set('lastObject.hasNext', false);
    },
    currentStep: Ember.computed('steps.@each.isCurrentStep', {
      get: function get() {
        return this.get('steps').findBy('isCurrentStep', true);
      }
    }),
    disableNext: Ember.computed('currentStep.fields.@each.hasValueIfMandatory', {
      get: function get() {
        return this.get('currentStep.fields').isAny('hasValueIfMandatory', false);
      }
    }),
    actions: {
      goToPreviousStep: function goToPreviousStep() {
        var steps = this.get('steps');
        var currentStep = this.get('currentStep');
        currentStep.setProperties({
          isCurrentStep: false,
          isVisited: false
        });
        var previousStepIndex = steps.indexOf(currentStep) - 1;
        steps.objectAt(previousStepIndex).set('isCurrentStep', true);
      },
      goToNextStep: function goToNextStep() {
        var _this = this;

        var currentStep = this.get('currentStep');
        currentStep.validate().then(function () {
          var steps = _this.get('steps');

          var nextStepIndex = steps.indexOf(currentStep) + 1;
          var nextStep = steps.objectAt(nextStepIndex);
          currentStep.set('isCurrentStep', false);
          nextStep.setProperties({
            isVisited: true,
            isCurrentStep: true
          });
        });
      },
      changeStep: function changeStep(step) {
        if (step.get('isVisited')) {
          var steps = this.get('steps');
          var currentStep = this.get('currentStep');
          var currentStepIndex = steps.indexOf(currentStep);
          var toBeCurrentStepIndex = steps.indexOf(step);
          currentStep.set('isCurrentStep', false);
          step.set('isCurrentStep', true);

          for (var i = toBeCurrentStepIndex + 1; i <= currentStepIndex; i++) {
            steps.objectAt(i).set('isVisited', false);
          }
        }
      },
      reset: function reset() {
        this.get('currentStep.fields').forEach(function (field) {
          field.reset();
        });
        this.toggleProperty('resetBoolean');
      },
      submit: function submit() {
        var _this2 = this;

        this.formSubmitAction().catch(function () {
          var steps = _this2.get('steps');

          var numberOfSteps = steps.get('length');

          for (var i = 0; i < numberOfSteps; i++) {
            var step = steps.objectAt(i);

            if (step.get('fields').isAny('hasErrors')) {
              _this2.send('changeStep', step);

              break;
            }
          }
        });
      },
      secondaryAction: function secondaryAction() {
        var _this3 = this;

        this.secondaryAction().catch(function () {
          var steps = _this3.get('steps');

          var numberOfSteps = steps.get('length');

          for (var i = 0; i < numberOfSteps; i++) {
            var step = steps.objectAt(i);

            if (step.get('fields').isAny('hasErrors')) {
              _this3.send('changeStep', step);

              break;
            }
          }
        });
      },
      clearErrors: function clearErrors() {
        this.set('form.errors', Ember.A());
      }
    }
  });

  _exports.default = _default;
});