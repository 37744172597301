define("happyfox-bi/manage/calculated-fields/components/operands/operand/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    datasetRelatedData: null,
    calculationType: null,
    operand: null,
    operandIndex: null,
    errors: null,
    additionalData: null,
    allowedOperandValueDataTypes: null,
    operandValue: Ember.computed.reads('operand.value'),
    operandTitle: Ember.computed('operandIndex', {
      get: function get() {
        return "Operand ".concat(parseInt(this.get('operandIndex')) + 1);
      }
    }),
    operandTypeOptions: Ember.computed('operandIndex', {
      get: function get() {
        var isFirstOperand = this.get('operandIndex') === 0;
        var operandTypes = Ember.A([{
          label: 'Dataset Field',
          value: 'dataset_field'
        }]);

        if (!isFirstOperand) {
          operandTypes.pushObject({
            label: 'Constant',
            value: 'constant'
          });
        }

        return operandTypes;
      }
    }),
    selectedOperandTypeOption: Ember.computed('operandTypeOptions.[]', 'operand.type', {
      get: function get() {
        var operandType = this.get('operand.type');
        return this.get('operandTypeOptions').findBy('value', operandType);
      }
    }),
    isValueTypeDatsetField: Ember.computed.equal('selectedOperandTypeOption.value', 'dataset_field'),
    isValueTypeConstant: Ember.computed.equal('selectedOperandTypeOption.value', 'constant'),
    isConstantTypeText: Ember.computed('allowedOperandValueDataTypes.[]', {
      get: function get() {
        return this.get('allowedOperandValueDataTypes').includes('text');
      }
    }),
    isRowLevelCalculation: Ember.computed.equal('calculationType', 'formula_field'),
    isAggregationBasedCalculation: Ember.computed.equal('calculationType', 'aggregation_formula'),
    actions: {
      fetchDataSetFields: function fetchDataSetFields() {
        var _this = this;

        // eslint-disable-next-line no-unused-vars
        return new Ember.RSVP.Promise(function (resolve, reject) {
          var datasetRelatedData = Ember.Object.create(_this.get('datasetRelatedData'));

          var allowedOperandValueDataTypes = _this.get('allowedOperandValueDataTypes'); // restrict dataset fields based on allowed operandIndex, the first one can be of any type but the rest should be of the same type as the first one.


          if (_this.get('operandIndex') !== 0 && Ember.isPresent(allowedOperandValueDataTypes)) {
            var datasetFields = datasetRelatedData.get('datasetFields');
            datasetFields = datasetFields.filter(function (field) {
              return allowedOperandValueDataTypes.includes(field.get('dataType'));
            });
            datasetRelatedData.set('datasetFields', datasetFields);
            resolve(datasetRelatedData);
          }

          resolve(datasetRelatedData);
        });
      },
      onOperandTypeChange: function onOperandTypeChange(selectedOption) {
        var existingOperand = this.get('operand');
        var operand = Ember.Object.create(this.get('operand'));
        operand.set('type', selectedOption.value);
        operand.set('value', null);
        this.onOperandChange(existingOperand, operand);
      },
      onDatasetFieldValueChange: function onDatasetFieldValueChange(value) {
        var existingOperand = this.get('operand');
        var operand = Ember.Object.create(this.get('operand'));
        operand.set('value', value);
        this.onOperandChange(existingOperand, operand);
      },
      onInputFieldValueChange: function onInputFieldValueChange(event) {
        var existingOperand = this.get('operand');
        var operand = Ember.Object.create(this.get('operand'));
        var newValue = this.isConstantTypeText ? event.target.value : Number(event.target.value);
        operand.set('value', newValue);
        this.onOperandChange(existingOperand, operand);
      }
    }
  });

  _exports.default = _default;
});