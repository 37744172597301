define("happyfox-bi/manage/calculated-fields/components/calculation-field/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROW_LEVEL_OPERATION_OPTIONS = [{
    label: 'Coalesce',
    value: 'coalesce'
  }, {
    label: 'Concat',
    value: 'concat'
  }];

  var _default = Ember.Component.extend({
    datasetRelatedData: null,
    calculationType: null,
    configuration: null,
    errors: null,
    additionalData: null,
    operation: Ember.computed.reads('configuration.operation'),
    operands: Ember.computed.reads('configuration.operands'),
    operationOptions: Ember.computed('calculationType', {
      get: function get() {
        if (this.get('calculationType') === 'formula_field') {
          return ROW_LEVEL_OPERATION_OPTIONS;
        } else {
          return [];
        }
      }
    }),
    selectedOperationOption: Ember.computed('operationOptions.[]', 'operation', {
      get: function get() {
        var operation = this.get('operation');
        return this.get('operationOptions').findBy('value', operation);
      }
    }),
    concatDelimiter: Ember.computed('configuration.operation', {
      get: function get() {
        var operation = this.get('operation');
        return operation === 'concat' ? this.get('configuration.concatDelimiter') : null;
      }
    }),
    isOperationConcat: Ember.computed('operation', {
      get: function get() {
        return this.get('operation') === 'concat';
      }
    }),
    actions: {
      onOperationChange: function onOperationChange(selectedOption) {
        var configuration = Ember.Object.create(this.get('configuration'));
        var operationValue = selectedOption.value;
        configuration.set('operation', selectedOption.value);
        configuration.set('operands', Ember.A([Ember.Object.create({
          'type': 'dataset_field',
          'value': null
        })]));

        if (operationValue === 'coalesce') {
          configuration.set('concatDelimiter', null);
        } else {
          delete configuration.concatDelimiter;
        }

        this.onConfigurationChange(configuration);
      },
      onOperandsChange: function onOperandsChange(operands) {
        var configuration = Ember.Object.create(this.get('configuration'));
        configuration.set('operands', operands);
        this.onConfigurationChange(configuration);
      },
      onConcatDelimiterChange: function onConcatDelimiterChange(concatDelimiter) {
        var configuration = Ember.Object.create(this.get('configuration'));
        configuration.set('concatDelimiter', concatDelimiter);
        this.onConfigurationChange(configuration);
      }
    }
  });

  _exports.default = _default;
});