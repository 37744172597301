define("happyfox-bi/components/wizard-flow/step-fields/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // Currently no common logic is present
  var _default = Ember.Component.extend({
    form: null,
    currentStepInfo: null
  });

  _exports.default = _default;
});