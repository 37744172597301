define("happyfox-bi/manage/calculated-fields/add/dataset-step/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dataService: Ember.inject.service('data'),
    data: null,
    errors: null,
    additionalData: null,
    displayName: Ember.computed.reads('data.displayName'),
    datasetOptions: Ember.computed('additionalData.datasets.[]', {
      get: function get() {
        var datasets = this.get('additionalData.datasets');
        return datasets.map(function (dataset) {
          return {
            label: dataset.get('nameWithDataSource'),
            value: dataset.get('id')
          };
        });
      }
    }),
    selectedDatasetOption: Ember.computed('datasetOptions.[]', 'data.dataset', {
      get: function get() {
        var datasetId = this.get('data.dataset');
        return this.get('datasetOptions').findBy('value', datasetId);
      }
    }),
    disableDatasetChange: Ember.computed('additionalData.isEditRoute', {
      get: function get() {
        // disable dataset change while editing
        return this.additionalData.isEditRoute;
      }
    }),
    init: function init() {
      var _this$data, _this$data$dataset;

      this._super.apply(this, arguments);

      var selectedDatasetOption = this.get('datasetOptions.firstObject');

      if ((_this$data = this.data) !== null && _this$data !== void 0 && (_this$data$dataset = _this$data.dataset) !== null && _this$data$dataset !== void 0 && _this$data$dataset.id) {
        var _this$data2, _this$data2$dataset, _this$data2$dataset$i;

        selectedDatasetOption = this.datasetOptions.findBy("value", this === null || this === void 0 ? void 0 : (_this$data2 = this.data) === null || _this$data2 === void 0 ? void 0 : (_this$data2$dataset = _this$data2.dataset) === null || _this$data2$dataset === void 0 ? void 0 : (_this$data2$dataset$i = _this$data2$dataset.id) === null || _this$data2$dataset$i === void 0 ? void 0 : _this$data2$dataset$i.toString());
      }

      this.send('onDatasetChange', selectedDatasetOption);
    },
    actions: {
      onDisplayNameChange: function onDisplayNameChange(displayName) {
        this.updateData({
          'displayName': displayName
        });
      },
      onDatasetChange: function onDatasetChange(selectedOption) {
        // Triggering getDataSetFields data service method to fetch fields in the background if not present in cache
        var datasetId = selectedOption.value;
        var datasetDetails = this.additionalData.get('datasets').findBy('id', datasetId);
        var dataSourceId = datasetDetails.get('dataSource');
        this.get('dataService').getDataSetFields(dataSourceId, datasetId, true);
        this.updateData({
          'dataset': datasetId
        });
      }
    }
  });

  _exports.default = _default;
});