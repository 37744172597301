define("happyfox-bi/manage/calculated-fields/components/actions-cell/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hkG5d+l0",
    "block": "{\"symbols\":[\"popOver\"],\"statements\":[[6,[37,6],null,[[\"isMini\",\"stopClickPropagation\"],[true,true]],[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"trigger\"]],\"expected `popOver.trigger` to be a contextual component but found a string. Did you mean `(component popOver.trigger)`? ('happyfox-bi/manage/calculated-fields/components/actions-cell/template.hbs' @ L2:C5) \"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,\"role\",\"button\"],[12],[2,\"\\n      \"],[1,[30,[36,4],[\"more-actions-vertical\"],[[\"class\"],[\"more_actions_svg\"]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[32,1,[\"isOpen\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[32,1,[\"content\"]],\"expected `popOver.content` to be a contextual component but found a string. Did you mean `(component popOver.content)`? ('happyfox-bi/manage/calculated-fields/components/actions-cell/template.hbs' @ L8:C7) \"],null]],[[\"class\"],[\"mod-more-options\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"section\"],[14,0,\"hf-pop-over_body\"],[12],[2,\"\\n        \"],[11,\"div\"],[24,0,\"hf-mini-pop-over_item\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"editCalculatedField\"],null],[12],[1,[30,[36,1],[\"edit\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"t\",\"-assert-implicit-component-helper-argument\",\"component\",\"inline-svg\",\"if\",\"pop-over\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/components/actions-cell/template.hbs"
    }
  });

  _exports.default = _default;
});