define("happyfox-bi/components/wizard-basic/step-base", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Object.extend({
    intlService: Ember.inject.service('intl'),
    labelKey: '',
    data: null,
    stepComponent: null,
    errors: null,
    hasNext: true,
    hasPrevious: true,
    isVisited: false,
    isCurrentStep: false,
    isVisitedOnce: false,
    validate: function validate() {},
    hasErrors: function hasErrors() {}
  });

  _exports.default = _default;
});