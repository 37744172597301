define("happyfox-bi/manage/calculated-fields/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mUxJ3vID",
    "block": "{\"symbols\":[],\"statements\":[[19,\"manage/calculated-fields/partials/create-edit-calculated-field\",[]]],\"hasEval\":true,\"upvars\":[]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/add/template.hbs"
    }
  });

  _exports.default = _default;
});