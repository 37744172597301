define("happyfox-bi/router", ["exports", "happyfox-bi/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    intlService: Ember.inject.service('intl'),
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    init: function init() {
      this._super();

      this.setPageTitle();
    },
    setPageTitle: function setPageTitle() {
      var _this = this;

      this.on('routeDidChange', function (transition) {
        if (transition.to) {
          var toRouteName = transition.to.name;
          var pageTitles = {
            'apps.details': function appsDetails() {
              return "".concat(_this.intlService.lookup('apps-caps'), " - ").concat(transition.pageTitleInfo.name.capitalize());
            }
          };
          var titleSegments = [];
          var titleSetter = pageTitles[toRouteName];

          if (titleSetter) {
            titleSegments.push(titleSetter());
          }

          titleSegments.push(_this.intlService.lookup('happyfox-bi'));
          document.title = titleSegments.join(' - ');
        }
      });
    }
  });
  Router.map(function () {
    this.route('login');
    this.route('register');
    this.route('reset-password');
    this.route('internal-login');
    this.route('chat', {
      path: '/chat/:visualization_id'
    });
    this.route('reports', function () {
      this.route('details', {
        path: '/:id'
      }, function () {
        this.route('pages', function () {
          this.route('details', {
            path: '/:page_id'
          });
        });
      });
    });
    this.route('data-sources', function () {
      this.route('details', {
        path: '/:id'
      }, function () {
        this.route('data-sets', function () {
          this.route('details', {
            path: '/:data_set_id'
          });
          this.route('import', {
            path: '/:data_set_id/import'
          });
        });
      });
    });
    this.route('visualizations', function () {
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('create');
      this.route('new', function () {
        this.route('edit', {
          path: '/:id/edit'
        });
        this.route('create');
      });
    });
    this.route('report-subscriptions');
    this.route('my-settings', {
      path: '/profile'
    });
    this.route('switch-account');
    this.route('apps', function () {
      this.route('details', {
        path: '/:name/'
      });
    });
    this.route('report-templates');
    this.route('visualization-examples');
    this.route('business-hours');
    this.route('page-screenshot', {
      path: '/reports/:report_id/pages/:page_id/screenshot'
    });
    this.route('manage', function () {
      this.route('account-settings');
      this.route('reports');
      this.route('users');
      this.route('calculated-fields', function () {
        this.route('add');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
    });
    this.route('lost', {
      path: '*:'
    });
    this.route('error', {
      path: '/error'
    });
  });
  var _default = Router;
  _exports.default = _default;
});