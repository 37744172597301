define("happyfox-bi/manage/calculated-fields/index/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Hem0TG9/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content_outlet_main calculated_fields_main\"],[12],[2,\"\\n  \"],[8,\"entity-list-view\",[],[[\"@columns\",\"@fetchData\",\"@handleGoToCreateEntity\",\"@showEmptyStateActionButton\",\"@emptyStateActionButtonText\",\"@emptyStateHeader\",\"@headerTitle\",\"@pageSearchPlaceholder\",\"@sortOptions\"],[[32,0,[\"calculatedFieldsColumn\"]],[32,0,[\"fetchCalculatedFields\"]],[32,0,[\"navigateToAddCalculatedField\"]],true,\"create-calculated-field\",\"no-calculated-fields\",\"calculated-fields\",\"search-calculated-fields\",[32,0,[\"sortOptions\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/index/template.hbs"
    }
  });

  _exports.default = _default;
});