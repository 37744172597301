define("happyfox-bi/mixins/data-set", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    dataService: Ember.inject.service('data'),
    getAllDatasets: function getAllDatasets() {
      var dataService = this.get('dataService');
      var dataSources = dataService.peekAllDataSources(); // If data sources are not loaded, fetch them first

      var dataSourcesPromise = dataSources && dataSources.length > 0 ? Promise.resolve(dataSources) : dataService.getAllDataSources();
      return dataSourcesPromise.then(function (fetchedDataSources) {
        dataSources = fetchedDataSources;
        var dataSourceIds = dataSources.mapBy('id');
        var promises = dataSourceIds.map(function (id) {
          return dataService.getDataSets(id, true);
        });
        return Ember.RSVP.all(promises).then(function (response) {
          var dataSets = Ember.A();
          response.forEach(function (data) {
            dataSets.addObjects(data);
          });
          var orderedDataSets = Ember.A();
          dataSources.forEach(function (dataSource) {
            var order = dataSource.get('settings.datasetsOrder');

            if (order) {
              order.forEach(function (dataSetId) {
                var dataSet = dataSets.findBy('id', dataSetId.toString());

                if (dataSet) {
                  orderedDataSets.addObject(dataSet);
                }
              });
            }
          });
          orderedDataSets.addObjects(dataSets); // Adding display name for each dataset with datasource name prefixed

          orderedDataSets.forEach(function (dataSet) {
            var dataSourceId = dataSet.get('dataSource');
            var dataSource = dataSources.findBy('id', dataSourceId.toString());
            var dataSetDisplayNameOrName = dataSet.get('displayNameOrName');
            var nameWithDataSource = "".concat(dataSource.get('name'), " - ").concat(dataSetDisplayNameOrName);
            dataSet.set('nameWithDataSource', nameWithDataSource);
          });
          return orderedDataSets;
        });
      });
    }
  });

  _exports.default = _default;
});