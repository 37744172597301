define("happyfox-bi/manage/calculated-fields/components/operands/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "byQ7YjJx",
    "block": "{\"symbols\":[\"error\",\"operand\",\"index\"],\"statements\":[[10,\"div\"],[14,0,\"operands-container\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,15]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,14],null,[[\"datasetRelatedData\",\"calculationType\",\"operand\",\"operandIndex\",\"allowedOperandValueDataTypes\",\"errors\",\"additionalData\",\"onOperandChange\",\"allowOperandDeletion\",\"removeOperand\"],[[35,13],[35,12],[32,2],[32,3],[35,11],[30,[36,10],[[35,9],[30,[36,8],[[35,0],[32,3]],null]],null],[35,7],[30,[36,4],[[32,0],\"onOperandChange\"],null],[35,6],[30,[36,4],[[32,0],\"removeOperand\"],null]]]]],[2,\"\\n\"]],\"parameters\":[2,3]}]]],[13],[2,\"\\n\"],[6,[37,10],[[35,16]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"button\"],[24,0,\"add-operand-button\"],[16,\"disabled\",[34,3]],[4,[38,4],[[32,0],\"addOperand\"],null],[12],[1,[30,[36,5],[\"add\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,10],[[35,17]],null,[[\"default\"],[{\"statements\":[[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"field-error-message is-error\"],[12],[1,[32,1]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"-track-array\",\"each\",\"disableOperandAddition\",\"action\",\"t\",\"allowOperandDeletion\",\"additionalData\",\"get\",\"showOperandItemsLevelErrors\",\"if\",\"allowedOperandValueDataTypes\",\"calculationType\",\"datasetRelatedData\",\"manage/calculated-fields/components/operands/operand\",\"operands\",\"showOperandAddition\",\"showOperandsLevelErrors\"]}",
    "meta": {
      "moduleName": "happyfox-bi/manage/calculated-fields/components/operands/template.hbs"
    }
  });

  _exports.default = _default;
});