define("happyfox-bi/manage/calculated-fields/components/operands/component", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; if (typeof Symbol === "function" && typeof Symbol.iterator === "symbol") { _typeof = function _typeof(obj) { return typeof obj; }; } else { _typeof = function _typeof(obj) { return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }; } return _typeof(obj); }

  var _default = Ember.Component.extend({
    datasetRelatedData: null,
    calculationType: null,
    operation: null,
    operands: null,
    errors: null,
    additionalData: null,
    allowOperandDeletion: Ember.computed('operation', 'operands.[]', {
      get: function get() {
        var operation = this.get('operation');
        var operandsCount = this.get('operands.length');

        if (operation === 'coalesce') {
          return operandsCount > 2;
        } else if (operation === 'concat') {
          return operandsCount > 2;
        }

        return false;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    showOperandAddition: Ember.computed('operation', 'operands.[]', 'operands.{firstObject.value}', {
      get: function get() {
        var operation = this.get('operation');

        if (operation === 'coalesce' || operation === 'concat') {
          return true;
        }

        return false;
      }
    }),
    // eslint-disable-next-line ember/use-brace-expansion
    disableOperandAddition: Ember.computed('operation', 'operands.[]', 'operands.{firstObject.value}', {
      get: function get() {
        var operation = this.get('operation');

        if ((operation === 'coalesce' || operation === 'concat') && !this.get('operands.firstObject.value')) {
          return true;
        }

        return false;
      }
    }),
    allowedOperandValueDataTypes: Ember.computed('operation', 'operands.firstObject.value.dataType', {
      get: function get() {
        if (this.get('operation') === 'concat') {
          return Ember.A(['text']);
        }

        var firstOperandDataType = this.get('operands.firstObject.value.dataType');

        if (firstOperandDataType) {
          return Ember.A([firstOperandDataType]);
        }

        return Ember.A();
      }
    }),
    showOperandItemsLevelErrors: Ember.computed('errors.[]', {
      get: function get() {
        var _this$errors;

        return _typeof((_this$errors = this.errors) === null || _this$errors === void 0 ? void 0 : _this$errors[0]) === "object";
      }
    }),
    showOperandsLevelErrors: Ember.computed('errors.[]', {
      get: function get() {
        var _this$errors2;

        return typeof ((_this$errors2 = this.errors) === null || _this$errors2 === void 0 ? void 0 : _this$errors2[0]) === "string";
      }
    }),
    actions: {
      addOperand: function addOperand() {
        var operands = Ember.A(this.get('operands'));
        operands.pushObject(Ember.Object.create({
          'type': 'dataset_field',
          'value': null
        }));
        this.onOperandsChange(operands);
      },
      removeOperand: function removeOperand(operand) {
        var operands = Ember.A(this.get('operands'));
        operands.removeObject(operand);
        this.onOperandsChange(operands);
      },
      onOperandChange: function onOperandChange(existingOperand, changedOperand) {
        existingOperand.setProperties(changedOperand);
        var operands = this.get('operands');
        this.onOperandsChange(operands);
      }
    }
  });

  _exports.default = _default;
});